import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import { media } from "../../utils/styled/responsive";
import LineReveal from "../../components/Text/LineReveal/LineReveal";

export const Landing = styled.div`
  position: relative;
  width: 100vw;
  height: 90vh;
  overflow: hidden;

  ${media.desktop} {
    height: 100vh;
    display: grid;
    align-items: center;
    grid-template-columns: 50vw 50vw;
    margin-right: 160px;

    max-width: 1600px;
  }
`;

export const Heading = styled(LineReveal)`
  width: min-content;
  transform: translateX(32px) translateY(25vh);
  & .spacer {
    height: 0;
  }

  ${media.tablet} {
    transform: translateX(64px) translateY(25vh);
  }

  ${media.desktop} {
    transform: translateX(80px);
    & div :nth-child(3) {
      transform: translateX(50%);
    }
    & div :nth-child(5) {
      transform: translateX(100%);
    }
  }
`;

export const Image = styled(GatsbyImage)`
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 120vw;
  object-fit: contain;

  ${media.tablet} {
    width: 60vw;
    right: 64px;
  }

  ${media.desktop} {
    right: unset;
    transform: translateX(-10vw) scale(0.9);
    align-self: end;
    width: unset;
    position: unset;
  }
`;
