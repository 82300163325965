import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import FadeInOut from "../../components/Animations/FadeInOut/FadeInOut";
import { Mobile } from "../../components/MediaQueries/MediaQueries";
import ProductList from "../../components/ProductList/ProductList";
import SideScroll from "../../components/Scrolls/SideScroll";
import { ScrollIndicator } from "../../sections/LandingSection/LandingSection.styles";

import Shader from "../../components/Shader/Shader";
import { Heading, Image, Landing } from "./WinePage.styles";

function WinePage({ data, pageContext, ...rest }) {
  const { image, title } = data.contentfulWinePage;

  console.log(pageContext.slug);

  const getSectionType = (
    url
  ): "czerwone" | "kolekcjonerskie" | "biale" | "rozowe" => {
    switch (url) {
      case "wina-biale":
      case "/en/wina-biale":
        return "biale";

      case "wina-rozowe":
      case "/en/wina-rozowe":
        return "rozowe";

      case "wina-czerwone":
      case "/en/wina-czerwone":
        return "czerwone";

      case "wina-kolekcjonerskie":
      case "/en/wina-kolekcjonerskie":
        return "kolekcjonerskie";

      default:
        return null;
    }
  };

  return (
    <FadeInOut>
      <SideScroll>
        <Mobile>
          <Shader />
        </Mobile>

        {getSectionType(pageContext.slug) !== "kolekcjonerskie" && (
          <Landing>
            <Heading renderAs="h1" text={title.title} />
            <Image image={getImage(image)} alt="" />
            <ScrollIndicator />
          </Landing>
        )}

        <ProductList
          passedData={data}
          extraSection
          pageUrl={getSectionType(pageContext.slug)}
        />
      </SideScroll>
    </FadeInOut>
  );
}

export default React.memo(WinePage);

export const data = graphql`
  query WinePage($locale: String) {
    allContentfulProduct(
      sort: { fields: priceNumber, order: ASC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          priceNumber
          node_locale
          id
          shortDescription
          url
          wineName
          year
          category
          image {
            gatsbyImageData(formats: [PNG], placeholder: BLURRED, quality: 100)
            title
            description
          }
        }
      }
    }

    contentfulWinePage(node_locale: { eq: $locale }) {
      node_locale
      title {
        title
      }
      image {
        gatsbyImageData(quality: 90)
        title
      }
    }
  }
`;
